var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDropzone } from 'react-dropzone';
// @mui
import { Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
// components
import Iconify from '../iconify';
import RejectionFiles from './errors/RejectionFiles';
import AvatarPreview from './preview/AvatarPreview';
// ----------------------------------------------------------------------
var StyledDropZone = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: 144,
        height: 144,
        margin: 'auto',
        display: 'flex',
        cursor: 'pointer',
        overflow: 'hidden',
        borderRadius: '50%',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'center',
        border: "1px dashed ".concat(alpha(theme.palette.grey[500], 0.32)),
    });
});
var StyledPlaceholder = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        zIndex: 7,
        display: 'flex',
        borderRadius: '50%',
        position: 'absolute',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: "calc(100% - 16px)",
        height: "calc(100% - 16px)",
        color: theme.palette.text.disabled,
        backgroundColor: theme.palette.background.neutral,
        transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
        }),
    });
});
// ----------------------------------------------------------------------
export default function UploadAvatar(_a) {
    var ModelImage = _a.ModelImage, error = _a.error, file = _a.file, disabled = _a.disabled, helperText = _a.helperText, sx = _a.sx, other = __rest(_a, ["ModelImage", "error", "file", "disabled", "helperText", "sx"]);
    var _b = useDropzone(__assign({ multiple: false, disabled: disabled }, other)), getRootProps = _b.getRootProps, getInputProps = _b.getInputProps, isDragActive = _b.isDragActive, isDragReject = _b.isDragReject, fileRejections = _b.fileRejections;
    var hasFile = !!file;
    var isError = isDragReject || !!error;
    return (_jsxs(_Fragment, { children: [_jsxs(StyledDropZone, __assign({}, getRootProps(), { sx: __assign(__assign(__assign(__assign(__assign({}, (isDragActive && {
                    opacity: 0.72,
                })), (isError && __assign({ borderColor: 'error.light' }, (hasFile && {
                    bgcolor: 'error.lighter',
                })))), (disabled && {
                    opacity: 0.48,
                    pointerEvents: 'none',
                })), (hasFile && {
                    '&:hover': {
                        '& .placeholder': {
                            opacity: 1,
                        },
                    },
                })), sx) }, { children: [_jsx("input", __assign({}, getInputProps())), hasFile && _jsx(AvatarPreview, { file: file }), _jsxs(StyledPlaceholder, __assign({ className: "placeholder", sx: __assign(__assign({ '&:hover': {
                                opacity: 0.72,
                            } }, (hasFile && {
                            zIndex: 9,
                            opacity: 0,
                            color: 'common.white',
                            bgcolor: function (theme) { return alpha(theme.palette.grey[900], 0.64); },
                        })), (isError && {
                            color: 'error.main',
                            bgcolor: 'error.lighter',
                        })) }, { children: [_jsx(Iconify, { icon: "ic:round-photo", width: 24, sx: { mb: 1 } }), _jsx(Typography, __assign({ variant: "caption" }, { children: ModelImage }))] }))] })), helperText && helperText, _jsx(RejectionFiles, { fileRejections: __spreadArray([], fileRejections, true) })] }));
}
